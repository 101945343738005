import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Row, Col,Button } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { getChildrenToRender } from './utils';

import { MDBCard, MDBCardBody, MDBNavLink,MDBCardTitle,MDBCardText,MDBBtn,MDBCol,MDBRow,MDBContainer} from "mdbreact";
import  fabrication from "../img/mbg.jpg";

import { enquireScreen } from 'enquire-js';
let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
let smobi;
if(isMobile){
   smobi={
    padding:"0px"
  }
}
const mButton = {
  display: "block",
  margin: "72px auto 0",
  background:" rgb(3, 67, 101)",
  background: "-moz-linear-gradient(315deg, #3f0d12 0%, #a71d31 74%)",
  background: "linear-gradient(315deg, #3f0d12 0%, #a71d31 74%)",
  boxShadow: "1px 5px 5px #260303",
  border: "none",
  transition:" background .45s @ease-out",
  width: "132px",
  lineHeight: "42px",
  height: "42px",
  borderRadius: "42px",
};

const murl="http://192.168.0.101/nw/img/v/";
const masonryOptions = {
    transitionDuration: 0
};
const theData = [
  {
    img: murl+"lmg.mp4",
    txt: 'Grilled salmon cubes with vegetables',
    t1:"We also provide conference backdrops and audio visal installations."
}
,
{
  img: murl+"lmg.mp4",
  txt: 'Grilled salmon cubes with vegetables',
  t1:"Salmon with deep fried vegetables and a touch of batter "
},
{
  img: murl+"lmg.mp4",
  txt: 'Grilled salmon cubes with vegetables',
  t1:"Salmon with deep fried vegetables and a touch of batter "
},
{ img: murl+"lmg.mp4",
txt:'Grilled salmon cubes with vegetables',
t1:"Salmon with deep fried vegetables and a touch of batter "
},
{
  img: murl+"lmg.mp4",
txt: 'Grilled salmon cubes with vegetables',
t1:"Salmon with deep fried vegetables and a touch of batter "
},
{
  img: murl+"lmg.mp4",
txt: 'thanksgiving-dinner',
t1:"Salmon with deep fried vegetables and a touch of batter "
}
]
class Content3 extends React.PureComponent {
  getDelay = (e, b) => (e % b) * 100 + Math.floor(e / b) * 100 + b * 100;

  render() {
    const { ...props } = this.props;
    const { dataSource, isMobile } = props;
    delete props.dataSource;
    delete props.isMobile;
    let clearFloatNum = 0;
    const children = dataSource.block.children.map((item, i) => {
      const childObj = item.children;
      const delay = isMobile ? i * 50 : this.getDelay(i, 24 / item.md);
      const liAnim = {
        opacity: 0,
        type: 'from',
        ease: 'easeOutQuad',
        delay,
      };
      const childrenAnim = { ...liAnim, x: '+=10', delay: delay + 100 };
      clearFloatNum += item.md;
      clearFloatNum = clearFloatNum > 24 ? 0 : clearFloatNum;
      return (
        <TweenOne
          component={Col}
          animation={liAnim}
          key={item.name}
          {...item}
          
          componentProps={{ md: item.md, xs: item.xs }}
          className={
            !clearFloatNum
              ? `${item.className || ''} clear-both`.trim()
              : item.className
          }
        >
        
          <div {...childObj.textWrapper}>
            <TweenOne
              key="h2"
              animation={childrenAnim}
              component="h5"
              {...childObj.title}
            >
              {childObj.title.children}
            </TweenOne>
            <br/><br/>
            <TweenOne
              key="p"
              animation={{ ...childrenAnim, delay: delay + 200 }}
              component="div"
              {...childObj.content}
            >
              {childObj.content.children}
            </TweenOne>
          </div>
        </TweenOne>
      );
    });
    const childElements = theData.map((e,i)=>{
      return (
        

 <MDBCard  style={{ width:"99%",maxWidth: "350px",marginRight: "72px",marginLeft: "",marginTop: "32px",cursor:"pointer"}}>

   {/* <img className="img-fluid" style={{maxWidth: "887px",width:"100%",}} src={e.img}  /> */}
 

   <MDBCardBody>
     <h5>{e.txt}</h5>
     <br/>
     <MDBCardText>
     {e.t1}
     </MDBCardText>
     
     <MDBNavLink to={{pathname:"/more",
           state: { url:e.img,H:e.txt,d:e.t1,C:"459"}
           }} >
     <MDBBtn color="light-blue" size="sm" >
                read more
              </MDBBtn>
       </MDBNavLink>
   </MDBCardBody>

 </MDBCard>
            
       );
   });
 
    return (
      <div {...props} {...dataSource.wrapper}>
        
    <MDBCard style={{ width:"100%",maxWidth: "1400px",marginRight: "auto",marginLeft: "auto",marginBottom:"372px",paddingBottom:"72px",boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",}} className="text-center">
        <MDBCardBody style={smobi}>
    
        <MDBCard style={{ width:"100%",maxWidth: "1400px",marginRight: "auto",marginLeft: "auto",marginBottom:"-172px",paddingBottom:"72px",marginTop:"173px",boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",}} className="text-center">
        <MDBCardBody  style={smobi}>
        <h5 style={{}}>Products and services</h5>
          <MDBContainer>
            <MDBRow>
              <MDBCol md="4">
                <MDBCard color="white lighten-1" style={{boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",maxWidth:"800px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">
                <img className="img-fluid" style={{width:"99%",maxWidth: "587px"}} src={fabrication}  />
                <MDBCardBody style={{color:"#697b8c"}}>
                  <h5 style={{}}>Installations</h5>
                  <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam velit nulla, lacinia nec varius id, tempor sed dolor. Sed eget tincidunt lacus, eu tempus nibh. Praesent lacinia ex a magna varius porta. Morbi ultrices posuere varius. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae;</p>
                
                </MDBCardBody>
              </MDBCard>
              </MDBCol>
              <MDBCol md="4">
                <MDBCard color="white lighten-1" style={{boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">
                <img className="img-fluid" style={{width:"99%",maxWidth: "587px"}} src={fabrication}  />
                    <MDBCardBody style={{color:"#697b8c"}}>
                      <h5 style={{}}>Maintainance</h5>
                      <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam velit nulla, lacinia nec varius id, tempor sed dolor. Sed eget tincidunt lacus, eu tempus nibh. Praesent lacinia ex a magna varius porta. Morbi ultrices posuere varius. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; </p>
                    
                    </MDBCardBody>
                </MDBCard>
              </MDBCol>
              <MDBCol md="4">
                <MDBCard color="white lighten-1" style={{boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">
                <img className="img-fluid" style={{width:"99%",maxWidth: "587px"}} src={fabrication}  />
                    <MDBCardBody style={{color:"#697b8c"}}>
                      <h5 style={{}}>Design</h5>
                      <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam velit nulla, lacinia nec varius id, tempor sed dolor. Sed eget tincidunt lacus, eu tempus nibh. Praesent lacinia ex a magna varius porta. Morbi ultrices posuere varius. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; </p>
                    
                    </MDBCardBody>
                </MDBCard>
              </MDBCol>
              <MDBCol md="4">
                <MDBCard color="white lighten-1" style={{boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">
                <img className="img-fluid" style={{width:"99%",maxWidth: "587px"}} src={fabrication}  />
                    <MDBCardBody style={{color:"#697b8c"}}>
                      <h5 style={{}}>Design</h5>
                      <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam velit nulla, lacinia nec varius id, tempor sed dolor. Sed eget tincidunt lacus, eu tempus nibh. Praesent lacinia ex a magna varius porta. Morbi ultrices posuere varius. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; </p>
                    
                    </MDBCardBody>
                </MDBCard>
              </MDBCol>
              <MDBCol md="4">
                <MDBCard color="white lighten-1" style={{boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">
                <img className="img-fluid" style={{width:"99%",maxWidth: "587px"}} src={fabrication}  />
                    <MDBCardBody style={{color:"#697b8c"}}>
                      <h5 style={{}}>Design</h5>
                      <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam velit nulla, lacinia nec varius id, tempor sed dolor. Sed eget tincidunt lacus, eu tempus nibh. Praesent lacinia ex a magna varius porta. Morbi ultrices posuere varius. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; </p>
                    
                    </MDBCardBody>
                </MDBCard>
              </MDBCol>
              <MDBCol md="4">
                <MDBCard color="white lighten-1" style={{boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">
                <img className="img-fluid" style={{width:"99%",maxWidth: "587px"}} src={fabrication}  />
                    <MDBCardBody style={{color:"#697b8c"}}>
                      <h5 style={{}}>Design</h5>
                      <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam velit nulla, lacinia nec varius id, tempor sed dolor. Sed eget tincidunt lacus, eu tempus nibh. Praesent lacinia ex a magna varius porta. Morbi ultrices posuere varius. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; </p>
                    
                    </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
           
          </MDBContainer>
        </MDBCardBody>
      </MDBCard> 
    
      </MDBCardBody>
      </MDBCard>
      </div>
    );
  }
}

export default Content3;
