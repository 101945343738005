import React from 'react';
import TweenOne from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { Row, Col } from 'antd';
import QueueAnim from 'rc-queue-anim';
import { MDBIcon,MDBCard, MDBCardBody, MDBCol, MDBRow, MDBContainer} from "mdbreact";
import { getChildrenToRender } from './utils';
import { enquireScreen } from 'enquire-js';
import  web from "../img/2a.gif";
import  soft from "../img/soft.jpeg";
import  mobi from "../img/apz.jpg";
import  micro from "../img/3a.gif";
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

let smobi;
let smobi1;
if(isMobile){
   smobi={
    padding:"0px"
  }
  smobi1={
    paddingLeft:"3px",
    paddingRight:"13px"
  }
}
class Content8 extends React.PureComponent {
  getDelay = (e, b) => (e % b) * 100 + Math.floor(e / b) * 100 + b * 100;
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      pad:"0px",
      
      
    };
    if(!isMobile){
      this.setState({
        pad:"70px"
      })
    }
  }
  getBlockChildren = (item, i) => {
    const children = item.children;
    const delay = this.props.isMobile ? i * 50 : this.getDelay(i, 24 / item.md);
    const liAnim = {
      y: 30,
      opacity: 0,
      type: 'from',
      ease: 'easeOutQuad',
      delay,
    };
  
    return (
      <TweenOne component={Col} animation={liAnim} key={i.toString()} {...item} style={{width:"100%",maxWidth:"450px",marginLeft:this.state.pad,marginRigt:"auto"}}>

         <MDBCard color="white lighten-1" style={{boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)"}} text="black" className="text-center">
        <MDBCardBody>
        <div {...children} style={{maxWidth:"7777px",marginLeft:"auto",marginRight:"auto"}} >
          {/* <div className="image-wrapper" {...children.img}>
            <img src={children.img.children} alt="img" />
          </div> */}
          <h2 {...children.title}>{children.title.children}</h2>
          <p style={{whiteSpace:"pre-wrap"}} {...children.content}>{children.content.children}</p>
        </div>
        </MDBCardBody>
        </MDBCard>
      </TweenOne>
    );
  };

  render() {
    const { ...props } = this.props;
    const { dataSource } = props;
    delete props.dataSource;
    delete props.isMobile;
    const children = dataSource.block.children.map(this.getBlockChildren);

    if(!isMobile){
      this.setState({
        pad:"70px"
      })
    }
  
    return (
      <div {...props} {...dataSource.wrapper}  style={smobi}>
     
     <div {...dataSource.page} style={smobi1}>
      <MDBCard color="white lighten-1" style={{marginLeft:"auto",marginRight:"auto",boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)"}} text="black" className="text-center">
        <MDBCardBody style={smobi}>

        {isMobile ? (
          <div {...dataSource.page} style={{marginLeft:"auto",marginRight:"auto",boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)"}} text="black" className="text-center">
          <MDBContainer>
            <MDBRow >
              <MDBCol md="6">
              <h5 style={{paddingTop:"12px",paddingBottom:"22px"}}>Website Development</h5>
                  <img className="img-fluid" style={{width:"99%",maxWidth: "387px"}} src={web}  />
              </MDBCol>
              <MDBCol md="6">
              <MDBCard color="white lighten-1" style={{boxShadow:"rgba(34, 94, 222, 0.08) 0px 32px 32px",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",}} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c"}}>
           <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >we offer cutting edge web development servicies using the latest technologies and frameworks. We feature responsive designs that incorporate are well displayed across all screen sizes.</p>
             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small"  label="Clean"/>
             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small" label="Responsive"/>
             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small"  label="Fast"/>
             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small"  label="Safe"/>
             <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >We employ technologies such as Material design for modern designs, react js for speed and responsiveness.</p>
           
           </MDBCardBody>
         </MDBCard>
              </MDBCol>
            </MDBRow>
            <MDBRow style={{marginTop:"72px"}}>
            <MDBCol md="6">
              <h5 style={{paddingTop:"12px",paddingBottom:"22px"}}>Mobile application development</h5>
              <img className="img-fluid" style={{width:"99%",maxWidth: "587px"}} src={mobi}  />
              </MDBCol>
              <MDBCol md="6">
              <MDBCard color="white lighten-1" style={{boxShadow:"rgba(34, 94, 222, 0.08) 0px 32px 32px",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",}} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c"}}>
           <p style={{whiteSpace:"pre-wrap",marginTop:"52px"}} >We develop cross platform mobile applications that run on android, windows and apple devices. We focus on using current technologies such as maps, integrated payment systems and ARcore to ensure any problem is met by a creative solution.</p>
             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small"  label="Maps"/>
             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small" label="Cross platform"/>
             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small"  label="Integrated payments"/>
             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small"  label="Ar & Vr technologies"/>
           
           </MDBCardBody>
         </MDBCard>
              </MDBCol>
            
            </MDBRow>
          </MDBContainer>
          <MDBContainer>
         
            <MDBRow style={{marginTop:"72px"}}>
                <MDBCol md="6">
                <h5 style={{paddingTop:"12px",paddingBottom:"22px"}}>Software development</h5>
                  <img className="img-fluid" style={{width:"99%",maxWidth: "587px"}} src={soft}  />
                </MDBCol>
              <MDBCol md="6">
              <MDBCard color="white lighten-1" style={{boxShadow:"rgba(34, 94, 222, 0.08) 0px 32px 32px",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",}} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c"}}>

           <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >Software technologies help to automate and simplify the day to day tasks of our lives. From ERP to computer games we strive to strive to stretch the limits of what is possible</p>
             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small"  label="Cross platform"/>
             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small" label="Multi languages"/>
             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small"  label="Enterprise resource planning"/>
             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small"  label="Games"/>

             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small"  label="Accounting"/>
             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small" label="E-commerce"/>
             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small"  label="Inventory"/>
             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small"  label="Analytics"/>
             <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >Imagination is the limit</p>
           
           </MDBCardBody>
         </MDBCard>
              </MDBCol>
             
            </MDBRow>
            <MDBRow style={{marginTop:"72px"}}>
              <MDBCol md="6">
              <h5 style={{paddingTop:"12px",paddingBottom:"22px"}}>Microcontroller programing</h5>
                  <img className="img-fluid" style={{width:"99%",maxWidth: "387px"}} src={micro}  />
              </MDBCol>
              <MDBCol md="6">
              <MDBCard color="white lighten-1" style={{boxShadow:"rgba(34, 94, 222, 0.08) 0px 32px 32px",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",}} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c"}}>
           <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >We offer soltions that help to utilise the full potential of mechanical apliances. With solutions ranging from smart homes to arduino projects microcontrollers have the benefit of making work easier at home and work places. </p>

            <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small"  label="Smart homes"/>
            <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small" label="Robotics"/>
            <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small"  label="Motion control"/>
            <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small"  label="Smart displays"/>
           
           </MDBCardBody>
         </MDBCard>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <br/><br/>
            </div>
         ):(
          
        <div {...dataSource.page} style={{marginLeft:"auto",marginRight:"auto",boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)"}} text="black" className="text-center">
   
     <MDBContainer>
       <MDBRow >
         <MDBCol md="6">
         <h5 style={{paddingTop:"12px",paddingBottom:"22px"}}>Website Development</h5>
             <img className="img-fluid" style={{width:"99%",maxWidth: "387px"}} src={web}  />
         </MDBCol>
         <MDBCol md="6">
         <MDBCard color="white lighten-1" style={{boxShadow:"rgba(34, 94, 222, 0.08) 0px 2px 0px",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",}} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c"}}>
             <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >we offer cutting edge web development servicies using the latest technologies and frameworks. We feture responsive designs that incorporate are well displayed across all screen sizes.</p>
             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small"  label="Clean"/>
             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small" label="Responsive"/>
             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small"  label="Fast"/>
             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small"  label="Safe"/>
             <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >We employ technologies such as Material design for modern designs, react js for speed and responsiveness.</p>
           
           
           </MDBCardBody>
         </MDBCard>
         </MDBCol>
       </MDBRow>
       <MDBRow style={{marginTop:"72px"}}>
         <MDBCol md="6">
           <MDBCard color="white lighten-1" style={{boxShadow:"rgba(34, 94, 222, 0.08) 0px 2px 0px",maxWidth:"700px",marginLeft:"auto",marginRight:"auto"}} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c",}}>
             
             <p style={{whiteSpace:"pre-wrap",marginTop:"52px"}} >We develop cross platform mobile applications that run on android, windows and apple devices. We focus on using current technologies such as maps, integrated payment systems and ARcore to ensure any problem is met by a creative solution.</p>
             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small"  label="Maps"/>
             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small" label="Cross platform"/>
             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small"  label="Integrated payments"/>
             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small"  label="Ar & Vr technologies"/>
           
           </MDBCardBody>
         </MDBCard>
         </MDBCol>
         <MDBCol md="6">
         <h5 style={{paddingTop:"12px",paddingBottom:"22px"}}>Mobile application development</h5>
         <img className="img-fluid" style={{width:"99%",maxWidth: "587px"}} src={mobi}  />
         </MDBCol>
       </MDBRow>
     </MDBContainer>
     <MDBContainer>
       <MDBRow style={{marginTop:"72px"}}>
         <MDBCol md="6">
         <h5 style={{paddingTop:"12px",paddingBottom:"22px"}}>Microcontroller programing</h5>
             <img className="img-fluid" style={{width:"99%",maxWidth: "387px"}} src={micro}  />
         </MDBCol>
         <MDBCol md="6">
         <MDBCard color="white lighten-1" style={{boxShadow:"rgba(34, 94, 222, 0.08) 0px 2px 0px",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",}} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c"}}>
             <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >We offer soltions that help to utilise the full potential of mechanical apliances. With solutions ranging from smart homes to arduino projects microcontrollers have the benefit of making work easier at home and work places. </p>

             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small"  label="Smart homes"/>
             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small" label="Robotics"/>
             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small"  label="Motion control"/>
             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small"  label="Smart displays"/>
           </MDBCardBody>
         </MDBCard>
         </MDBCol>
       </MDBRow>
       <MDBRow style={{marginTop:"72px"}}>
         <MDBCol md="6">
           <MDBCard color="white lighten-1" style={{boxShadow:"rgba(34, 94, 222, 0.08) 0px 2px 0px",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",}} text="black" className="text-center">
           <MDBCardBody style={{color:"#697b8c"}}>
             <h5 style={{paddingTop:"12px",paddingBottom:"22px"}}>Software development</h5>
             <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >Software technologies helpt to automate and simplify the day to day tasks of our lives. From ERP to computer gamess we strive to strive to stretch the limits of what is possible</p>
             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small"  label="Cross platform"/>
             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small" label="Multi languages"/>
             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small"  label="Enterprise resource planning"/>
             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small"  label="Games"/>

             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small"  label="Accounting"/>
             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small" label="E-commerce"/>
             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small"  label="Inventory"/>
             <Chip style={{marginTop:"22px",marginRight:"12px"}} variant="outlined" size="small"  label="Analytics"/>
             <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >Imagination is the limit</p>
           
           </MDBCardBody>
         </MDBCard>
         </MDBCol>
         <MDBCol md="6">
   
         <img className="img-fluid" style={{width:"99%",maxWidth: "487px"}} src={soft}  />
         </MDBCol>
       </MDBRow>
     </MDBContainer>
       </div>
  )}
        </MDBCardBody>
        </MDBCard>
        
      

        </div>
      </div>
    );
  }
}

export default Content8;
