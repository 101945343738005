import React from 'react';
import QueueAnim from 'rc-queue-anim';
import { TweenOneGroup } from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { Row, Col,Button} from 'antd';
import { page1 } from './data.source';
import {MDBCardBody,MDBCard} from "mdbreact";
import timg from "../img/t.svg";

import {Redirect,Link } from "react-router-dom";

const pointPos = [
  { x: -90, y: -20 },
  { x: 35, y: -25 },
  { x: -120, y: 125 },
  { x: -100, y: 165 },
  { x: 95, y: -5 },
  { x: 90, y: 160, opacity: 0.2 },
  { x: 110, y: 50 },
];
const mButton = {
  display: "block",
  margin: "72px auto 0",
  background:" rgb(3, 67, 101)",
  background: "-moz-linear-gradient(315deg, #3f0d12 0%, #a71d31 74%)",
  background: "linear-gradient(315deg, #3f0d12 0%, #a71d31 74%)",
  boxShadow: "1px 5px 5px #260303",
  border: "none",
  transition:" background .45s @ease-out",
  width: "132px",
  lineHeight: "42px",
  height: "42px",
  marginTop:"22px",
  borderRadius: "42px",
};
export default class Design extends React.PureComponent {
  state = {
    hoverNum: null,
  }
  onMouseOver = (i) => {
    this.setState({
      hoverNum: i,
    });
  }

  onMouseOut = () => {
    this.setState({
      hoverNum: null,
    });
  }
  getEnter = (e) => {
    const i = e.index;
    const r = (Math.random() * 2) - 1;
    const y = (Math.random() * 10) + 5;
    const delay = Math.round(Math.random() * (i * 50));
    return [
      {
        delay, opacity: 0.4, ...pointPos[e.index], ease: 'easeOutBack', duration: 300,
      },
      {
        y: r > 0 ? `+=${y}` : `-=${y}`,
        duration: (Math.random() * 1000) + 2000,
        yoyo: true,
        repeat: -1,
      }];
  }
  go=()=>{
    // alert("foo")
    return(  <Redirect to='/hack'/>);
  }
  render() {
    const { hoverNum } = this.state;
    const { isMobile } = this.props;
    const children = page1.children.map((item, i) => {
      const isHover = hoverNum === i;
      const pointChild = [
        'point-ring left', 'point-ring point-ring-0 right',
        'point-0', 'point-2', 'point-1', 'point-3', 'point-2',
      ].map((className, ii) => (
        <i
          className={className}
          key={ii.toString()}
          style={{
            background: item.color,
            borderColor: item.color,
          }}
        />
      ));
      return (
        <Col style={{}} md={6} xs={24} key={i.toString()} className="page1-item">
    
          <a
            className="page1-item-link"
            onMouseEnter={() => { this.onMouseOver(i); }}
            onMouseLeave={this.onMouseOut}
            onClick={this.go()}
          >
            <TweenOneGroup
              enter={this.getEnter}
              leave={{
                x: 0, y: 30, opacity: 0, duration: 10, ease: 'easeInBack',
              }}
              resetStyleBool={false}
              className="point-wrapper"
            >
              {(isHover || isMobile) && pointChild}
            </TweenOneGroup>
            <div className="page1-item-img" style={{color:"white", boxShadow: `0 16px 32px ${item.shadowColor}` }}>
              <img style={{width:"120px"}}src={item.src} alt="img" />
            </div>
            <div className="page1-item-title" style ={{color:"white",marginTop:"22px"}}>{item.title}</div>
            {/* <p>{item.content}</p> */}
            
          </a>
          <Link  to="/services">
                <Button style={mButton} type="primary"> More
                </Button>
              </Link> 
         <br/> <br/>
        </Col>);
    });
    return (
      <div className="page-wrapper page1" style={{background: "linear-gradient(147deg, rgb(44, 62, 80)  7%,black 4%,black 90%, rgb(44, 62, 80) 4%)", marginTop:"-42px"}}>
        <div className="page">
          <h1 style={{color:"white",}}>{page1.title}</h1>
          <OverPack style={{color:"white",marginTop:"-22px"}}>
            <QueueAnim key="queue" type="bottom" leaveReverse component={Row}>
              {children}
            </QueueAnim>
          </OverPack>
       
        </div>
      </div>);
  }
}
