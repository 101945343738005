import React, { useState, useCallback } from "react";

import  fabrication from "./img/mbg.png";
import {Link } from "react-router-dom";

import { MDBCard, MDBCardBody, MDBContainer,MDBCol,MDBRow,MDBIcon} from "mdbreact";
import { enquireScreen } from 'enquire-js';
import Texty from 'rc-texty';


import Footer0 from './Home/Footer0';
 const Footer20DataSource = {
  wrapper: { className: 'home-page-wrapper footer2-wrapper' },
  OverPack: { className: 'home-page footer2', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: [
   
      {
        name: 'group',
        children: ' Designed by losenviskas',
        className: 'copyright-group',
      },
      {
        name: 'image2',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/fgGmQUfiUfSBfvsQpfOj.svg',
        className: 'copyright-line',
      },
      {
        name: 'copyright',
        children: 'Copyright © 2020',
        className: 'copyright-text',
      },
    ],
  },

};

let isMobile;
let rbg;
rbg={height:"100vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+fabrication+")"}
enquireScreen((b) => {
  isMobile = b;
});
let smobi;
if(isMobile){
   smobi={
    padding:"0px"
  }
}
if(isMobile){
  rbg={height:"100vh",width:"100%",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center",backgroundImage:"url("+fabrication+")"}
}

const mButton = {
  display: "block",
  margin: "72px auto 0",
  background:" rgb(3, 67, 101)",
  background: "-moz-linear-gradient(to right, #034365 0%, #00070c 79%)",
  background: "linear-gradient(to right, #034365 0%, #00070c 79%)",
  boxShadow: "0px 4px 5px 0px #9da8b6",
  border: "none",
  transition:" background .45s @ease-out",
  width: "132px",
  lineHeight: "42px",
  height: "42px",
  marginTop:"22px",
  borderRadius: "42px",
};
function App() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    
      if(viewerIsOpen){
        // alert(viewerIsOpen);
        setCurrentImage(0);
        setViewerIsOpen(false);
      }
  };
  const al = () => {
    
   alert("foo");
};
  return (
   
    <div  style={{backgroundColor:"transparent",backgroundAttachment: "fixed",backgroundSize:"cover",backgroundPosition: "center" ,marginTop:"-2px"}}>
        <div style={rbg}>
   
 
            <div style={{color:"white",paddingTop:"70vh",marginLeft:"auto",marginRight:"auto",}}>
            <Texty style={{fontSize: "58px",lineHeight: "80px", textIndent: "2px",fontWeight: "600",margin: "26px auto 38px",overflow: "hidden"}} className="text-center"type="mask-bottom">Contact us</Texty>

            </div>
        
        {/* <MDBContainer >
        <MDBRow >
          <MDBCol md="4" style={{marginTop:"40vh"}}>
            <MDBCard color="white lighten-1" style={{boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",maxWidth:"800px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">
            <MDBCardBody style={{color:"#697b8c"}}>
              <h5 style={{}}>Website development</h5>
              <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >Price range ksh. 10000-100000 <br/><br/>The price will vary depending on the website you require.</p> 

                <br/>
                <Link  to="/Services">
                <u>contact us for more info</u> 
              </Link> 
            
            </MDBCardBody>
          </MDBCard>
          </MDBCol>
          <MDBCol md="4" style={{marginTop:"40vh"}}>
            <MDBCard color="white lighten-1" style={{boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">
                <MDBCardBody style={{color:"#697b8c"}}>
                  <h5 style={{}}>Mobile applications</h5>
                  <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >Price From 20000 ksh<br/><br/>We charge mobile apps based on the content and complexity </p>
                  
                   <br/>
                <Link  to="/Services">
                <u>contact us for more info</u> 
              </Link> 
                
                </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md="4" style={{marginTop:"83px"}}>
            <MDBCard color="white lighten-1" style={{boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">
                <MDBCardBody style={{color:"#697b8c"}}>
                  <h5 style={{}}>Software development</h5>
                  <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >Price From ksh. 25000 <br/><br/>We charge software development based on type and complexity </p>
                
                  <br/>
                <Link  to="/Services">
                <u>contact us for more info</u> 
              </Link>
                </MDBCardBody>
            </MDBCard>
          </MDBCol>


        </MDBRow>
       
      </MDBContainer> */}
        </div>

        <MDBCard style={{ width:"100%",maxWidth: "1400px",marginRight: "auto",marginLeft: "auto",paddingBottom:"72px",marginTop:"-53px",backgroundSize:"cover",background:"linear-gradient(146deg, rgb(44, 62, 80) 8%, black 8%, black 25%, white 9%, white 92%, black 7%, black 98%, rgb(44, 62, 80) 1%) center center / cover fixed"}} className="text-center">
    <MDBCardBody  style={smobi}>
 <MDBCard style={{ width:"100%",maxWidth: "1400px",marginRight: "auto",marginLeft: "auto",marginBottom:"-172px",paddingBottom:"72px",marginTop:"3px",backgroundSize:"cover",background:"linear-gradient(147deg, rgba(8, 0, 0, 0.01) 10%, rgb(255, 255, 255) 4%, rgb(255, 255, 255) 97%, rgba(0, 0, 0, 0.06) 4%)",backgroundAttachment: "fixed",backgroundPosition: "center"}} className="text-center">
    <MDBCardBody  style={smobi}>

    <h5 style={{color:"white"}}>Samples</h5>
      <MDBContainer>
        <MDBRow>
          <MDBCol md="4">
            <MDBCard color="white lighten-1" style={{boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",maxWidth:"800px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">
              <MDBIcon icon="mobile-alt" size="2x"  className="indigo-text pr-3"/>
            <MDBCardBody style={{color:"#697b8c"}}>
              <h5 style={{}}>Mobile </h5>
              <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >Phone1: +254-713-772-837</p> 
             
              <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >Phone2: +254-724-501-643</p> 
              
              <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >Phone2: +254-702-923-950</p> 
                <br/>
            
            </MDBCardBody>
          </MDBCard>
          </MDBCol>
          <MDBCol md="4">
          <MDBCard color="white lighten-1" style={{boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",maxWidth:"800px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">
              <MDBIcon icon="map-marker-alt" size="2x"  className="indigo-text pr-3"/>
            <MDBCardBody style={{color:"#697b8c"}}>
              <h5 style={{}}>Address </h5>
              <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} > Ruiru 3rd Sunrise Avenue Jana Complex</p> 
                <br/>
          
            
            </MDBCardBody>
          </MDBCard>
          </MDBCol>
          <MDBCol md="4">
          <MDBCard color="white lighten-1" style={{boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",maxWidth:"800px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">
              <MDBIcon icon="envelope" size="2x"  className="indigo-text pr-3"/>
            <MDBCardBody style={{color:"#697b8c"}}>
              <h5 style={{}}>Email </h5>
              <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >Email: info@losenviskas.com</p> 
                <br/>
          
            
            </MDBCardBody>
          </MDBCard>
          </MDBCol>
          
        </MDBRow>
       
      </MDBContainer>
    </MDBCardBody>
  </MDBCard> 
    </MDBCardBody>
  </MDBCard> 
  <Footer0
        id="Footer0_0"
        key="Footer0_0"
        dataSource={Footer20DataSource}
        // isMobile={this.state.isMobile}
      />
    </div>
  );
}
// render(<App />, document.getElementById("app"));

export default  App;