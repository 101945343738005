import React from 'react';
import { MDBCard, MDBCardBody, MDBCol,MDBRow,MDBBtn,MDBContainer} from "mdbreact";
import  fabrication from "../img/mbg.jpg";

import { enquireScreen } from 'enquire-js';
let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
let smobi;
if(isMobile){
   smobi={
    padding:"0px"
  }
}
class Banner extends React.PureComponent {
  state = {
    st: "",
    mc:"white"
  }
  render() {
    const { ...currentProps } = this.props;
    const { dataSource } = currentProps;
    delete currentProps.dataSource;
    // delete currentProps.isMobile;
    if(currentProps.isMobile){
this.setState({
  st:"200px",
  mc:"black"
})
    }
    return (
      
    <div  {...dataSource.wrapper} style={{height:""}}>
      
    <MDBCard style={{ width:"100%",maxWidth: "1400px",marginRight: "auto",marginLeft: "auto",marginBottom:"372px",paddingBottom:"72px",boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",}} className="text-center">
        <MDBCardBody style={smobi}>
    
        <MDBCard style={{ width:"100%",maxWidth: "1400px",marginRight: "auto",marginLeft: "auto",marginBottom:"-172px",paddingBottom:"72px",marginTop:"173px",boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",}} className="text-center">
        <MDBCardBody  style={smobi}>
        <h5 style={{}}>Products and services</h5>
          <MDBContainer>
            <MDBRow>
              <MDBCol md="4">
                <MDBCard color="white lighten-1" style={{boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",maxWidth:"800px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">
                <img className="img-fluid" style={{width:"99%",maxWidth: "587px"}} src={fabrication}  />
                <MDBCardBody style={{color:"#697b8c"}}>
                  <h5 style={{}}>Installations</h5>
                  <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam velit nulla, lacinia nec varius id, tempor sed dolor. Sed eget tincidunt lacus, eu tempus nibh. Praesent lacinia ex a magna varius porta. Morbi ultrices posuere varius. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae;</p>
                
                </MDBCardBody>
              </MDBCard>
              </MDBCol>
              <MDBCol md="4">
                <MDBCard color="white lighten-1" style={{boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">
                <img className="img-fluid" style={{width:"99%",maxWidth: "587px"}} src={fabrication}  />
                    <MDBCardBody style={{color:"#697b8c"}}>
                      <h5 style={{}}>Maintainance</h5>
                      <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam velit nulla, lacinia nec varius id, tempor sed dolor. Sed eget tincidunt lacus, eu tempus nibh. Praesent lacinia ex a magna varius porta. Morbi ultrices posuere varius. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; </p>
                    
                    </MDBCardBody>
                </MDBCard>
              </MDBCol>
              <MDBCol md="4">
                <MDBCard color="white lighten-1" style={{boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">
                <img className="img-fluid" style={{width:"99%",maxWidth: "587px"}} src={fabrication}  />
                    <MDBCardBody style={{color:"#697b8c"}}>
                      <h5 style={{}}>Design</h5>
                      <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam velit nulla, lacinia nec varius id, tempor sed dolor. Sed eget tincidunt lacus, eu tempus nibh. Praesent lacinia ex a magna varius porta. Morbi ultrices posuere varius. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; </p>
                    
                    </MDBCardBody>
                </MDBCard>
              </MDBCol>
              <MDBCol md="4">
                <MDBCard color="white lighten-1" style={{boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">
                <img className="img-fluid" style={{width:"99%",maxWidth: "587px"}} src={fabrication}  />
                    <MDBCardBody style={{color:"#697b8c"}}>
                      <h5 style={{}}>Design</h5>
                      <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam velit nulla, lacinia nec varius id, tempor sed dolor. Sed eget tincidunt lacus, eu tempus nibh. Praesent lacinia ex a magna varius porta. Morbi ultrices posuere varius. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; </p>
                    
                    </MDBCardBody>
                </MDBCard>
              </MDBCol>
              <MDBCol md="4">
                <MDBCard color="white lighten-1" style={{boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">
                <img className="img-fluid" style={{width:"99%",maxWidth: "587px"}} src={fabrication}  />
                    <MDBCardBody style={{color:"#697b8c"}}>
                      <h5 style={{}}>Design</h5>
                      <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam velit nulla, lacinia nec varius id, tempor sed dolor. Sed eget tincidunt lacus, eu tempus nibh. Praesent lacinia ex a magna varius porta. Morbi ultrices posuere varius. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; </p>
                    
                    </MDBCardBody>
                </MDBCard>
              </MDBCol>
              <MDBCol md="4">
                <MDBCard color="white lighten-1" style={{boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">
                <img className="img-fluid" style={{width:"99%",maxWidth: "587px"}} src={fabrication}  />
                    <MDBCardBody style={{color:"#697b8c"}}>
                      <h5 style={{}}>Design</h5>
                      <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam velit nulla, lacinia nec varius id, tempor sed dolor. Sed eget tincidunt lacus, eu tempus nibh. Praesent lacinia ex a magna varius porta. Morbi ultrices posuere varius. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; </p>
                    
                    </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
           
          </MDBContainer>
        </MDBCardBody>
      </MDBCard> 
    
      </MDBCardBody>
      </MDBCard>
        </div>
    );
  }
}
export default Banner;
