import * as React from 'react';
import Masonry from 'react-masonry-component';
import { MDBCard, MDBCardBody, MDBNavLink,MDBCardTitle,MDBCardText,MDBBtn,MDBCol} from "mdbreact";
import { Player } from 'video-react';
import { enquireScreen } from 'enquire-js';
let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
 
const murl="http://192.168.0.101/nw/img/v/";
const masonryOptions = {
    transitionDuration: 0
};

const imagesLoadedOptions = { background: '.my-bg-image-el' }
const theData = [
  {
    img: murl+"lmg.mp4",
    txt: 'Grilled salmon cubes with vegetables',
    t1:"We also provide conference backdrops and audio visal installations."
}
,
{
  img: murl+"lmg.mp4",
  txt: 'Grilled salmon cubes with vegetables',
  t1:"Salmon with deep fried vegetables and a touch of batter "
},
{
  img: murl+"lmg.mp4",
  txt: 'Grilled salmon cubes with vegetables',
  t1:"Salmon with deep fried vegetables and a touch of batter "
},
{ img: murl+"lmg.mp4",
txt:'Grilled salmon cubes with vegetables',
t1:"Salmon with deep fried vegetables and a touch of batter "
},
{
  img: murl+"lmg.mp4",
txt: 'Grilled salmon cubes with vegetables',
t1:"Salmon with deep fried vegetables and a touch of batter "
},
{
  img: murl+"lmg.mp4",
txt: 'thanksgiving-dinner',
t1:"Salmon with deep fried vegetables and a touch of batter "
}
]
class Services extends React.Component {
 
 
    render() {
        const childElements = theData.map((e,i)=>{
           return (
                // <li className="image-element-class">
                //   {e.txt}
                //     <img src={e.img}/>
                // </li>

      <MDBCard  style={{ width:"99%",maxWidth: "350px",marginRight: "72px",marginLeft: "",marginTop: "32px",cursor:"pointer"}}>

        {/* <img className="img-fluid" style={{maxWidth: "887px",width:"100%",}} src={e.img}  /> */}
      
        <video lassName="img-fluid" style={{width:"100%",}}>
      <source src={e.img}/>
    </video>
        <MDBCardBody>
          <h5>{e.txt}</h5>
          <br/>
          <MDBCardText>
          {e.t1}
          </MDBCardText>
          
          <MDBNavLink to={{pathname:"/more",
                state: { url:e.img,H:e.txt,d:e.t1,C:"459"}
                }} >
          <MDBBtn color="light-blue" size="sm" >
                     read more
                   </MDBBtn>
            </MDBNavLink>
        </MDBCardBody>
     
      </MDBCard>
                 
            );
        });
      
        return (<div style={{maxWidth:"",marginLeft:"auto",marginRight:"auto",paddingTop:"52px",}}>

    <MDBCard style={{ width:"95%",maxWidth: "1400px",marginRight: "auto",marginLeft: "auto",marginTop: "2px",boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)"}} className="text-center">
      <MDBCardBody>

      <h4>Dishes offered</h4>
  
  
 {isMobile ? (      <Masonry
                className={'my-gallery-class'} // default ''
                elementType={'div'} // default 'div'
                options={masonryOptions} // default {}
                disableImagesLoaded={false} // default false
                updateOnEachImageLoad={true} // default false and works only if disableImagesLoaded is false
                imagesLoadedOptions={imagesLoadedOptions} // default {}
                
                style={{ maxWidth:"1000px",marginLeft:"-12px",marginRight:"auto",marginBottom: "22px"}} 
            >
                {childElements}
               
        </Masonry>
      ) : (      <Masonry
        className={'my-gallery-class'} // default ''
        elementType={'div'} // default 'div'
        options={masonryOptions} // default {}
        disableImagesLoaded={false} // default false
        updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
        imagesLoadedOptions={imagesLoadedOptions} // default {}
        
        style={{ maxWidth:"1000px",marginLeft:"auto",marginRight:"auto",marginBottom: "22px"}} 
    >
        {childElements}
       
</Masonry>
      )}


        </MDBCardBody>
      </MDBCard>
</div>
        
        );
    }
}
export default Services;