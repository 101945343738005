import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Row, Col } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { MDBCard, MDBCardBody, MDBCol,MDBRow,MDBBtn,MDBContainer} from "mdbreact";
import  fabrication from "../img/mbg.jpg";

import  app from "../img/s/a.png";
import  game from "../img/s/g.png";
import  sw from "../img/s/s.png";
import  wb1 from "../img/s/w1.png";
import  wb2 from "../img/s/w2.png";
import  wb3 from "../img/s/w3m.png";

import { enquireScreen } from 'enquire-js';
let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
let smobi;
if(isMobile){
   smobi={
    padding:"0px"
  }
}
function Content1(props) {
  const { ...tagProps } = props;
  const { dataSource, isMobile } = tagProps;
  delete tagProps.dataSource;
  delete tagProps.isMobile;
  const animType = {
    queue: isMobile ? 'bottom' : 'right',
    one: isMobile
      ? {
          scaleY: '+=0.3',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        }
      : {
          x: '-=30',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        },
  };
  return (
    <div {...tagProps} {...dataSource.wrapper} style={{height:"100%",backgroundColor:"black"}}>
{/*       
<MDBCard style={{ width:"100%",maxWidth: "1400px",marginRight: "auto",marginLeft: "auto",marginBottom:"372px",paddingBottom:"2px",boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",  background:  "linear-gradient(147deg, rgb(44, 62, 80)  15%,black 4%,black 87%, rgb(44, 62, 80) 4%)",backgroundSize:"cover",backgroundAttachment: "fixed",backgroundPosition: "center"}} className="text-center"> */}
 {/* <MDBCard style={{ width:"100%",maxWidth: "1400px",marginRight: "auto",marginLeft: "auto",paddingBottom:"72px",marginTop:"3px",backgroundSize:"cover",background:"linear-gradient(146deg, rgb(44, 62, 80) 8%, black 8%, black 25%, white 9%, white 92%, black 7%, black 98%, rgb(44, 62, 80) 1%) center center / cover fixed"}} className="text-center">
    <MDBCardBody  style={smobi}>
 <MDBCard style={{ width:"100%",maxWidth: "1400px",marginRight: "auto",marginLeft: "auto",marginBottom:"-172px",paddingBottom:"72px",marginTop:"3px",backgroundSize:"cover",background:"linear-gradient(147deg, rgba(8, 0, 0, 0.01) 10%, rgb(255, 255, 255) 4%, rgb(255, 255, 255) 97%, rgba(0, 0, 0, 0.06) 4%)",backgroundAttachment: "fixed",backgroundPosition: "center"}} className="text-center">
    <MDBCardBody  style={smobi}>
    <h5 style={{}}>Samples</h5>
      <MDBContainer>
        <MDBRow>
          <MDBCol md="4">
            <MDBCard color="white lighten-1" style={{boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",maxWidth:"800px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">
            <img className="img-fluid" style={{width:"99%",maxWidth: "587px"}} src={wb1}  />
            <MDBCardBody style={{color:"#697b8c"}}>
         
            
            </MDBCardBody>
          </MDBCard>
          </MDBCol>
          <MDBCol md="4">
            <MDBCard color="white lighten-1" style={{boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">
            <img className="img-fluid" style={{width:"99%",maxWidth: "587px"}} src={wb2}  />
                <MDBCardBody style={{color:"#697b8c"}}>
               
                
                </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md="4">
            <MDBCard color="white lighten-1" style={{boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">
            <img className="img-fluid" style={{width:"99%",maxWidth: "587px"}} src={wb3}  />
                <MDBCardBody style={{color:"#697b8c"}}>
               
                
                </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md="4">
            <MDBCard color="white lighten-1" style={{boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">
            <img className="img-fluid" style={{width:"99%",maxWidth: "587px"}} src={sw}  />
                <MDBCardBody style={{color:"#697b8c"}}>
                
                
                
                </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md="4">
            <MDBCard color="white lighten-1" style={{boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">
            <img className="img-fluid" style={{width:"99%",maxWidth: "587px"}} src={app}  />
                <MDBCardBody style={{color:"#697b8c"}}>
                  
                
                </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md="4">
            <MDBCard color="white lighten-1" style={{boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">
            <img className="img-fluid" style={{width:"99%",maxWidth: "587px"}} src={game}  />
                <MDBCardBody style={{color:"#697b8c"}}>
            
       
                
                </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
       
      </MDBContainer> 
        <MDBCard color="white lighten-1" style={{boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",marginBottom:"2px"}} text="black" className="text-center">
            <img className="img-fluid" style={{width:"99%",maxWidth: "587px"}} src={game}  />
                <MDBCardBody style={{color:"#697b8c"}}>
            
       We strive to ensure the deigns and de       We strive to ensure the deigns and de       We strive to ensure the deigns and de       We strive to ensure the deigns and de       We strive to ensure the deigns and de
                
                </MDBCardBody>
            </MDBCard>
    </MDBCardBody>
  </MDBCard> 
    </MDBCardBody>
  </MDBCard>  */}
    </div>
  );
}

export default Content1;
