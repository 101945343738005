import React, { useState, useCallback } from "react";
import { render } from "react-dom";
import  fabrication from "./img/mbg.jpg";
import {Link } from "react-router-dom";

// import Footer20DataSource from './Home3/data.source'

import { MDBCard, MDBCardBody, MDBContainer,MDBCol,MDBRow,} from "mdbreact";
import { enquireScreen } from 'enquire-js';
import FontAwesome from 'react-fontawesome';
import { Table, Tag } from 'antd';

import Footer0 from './Home/Footer0';
 const Footer20DataSource = {
  wrapper: { className: 'home-page-wrapper footer2-wrapper' },
  OverPack: { className: 'home-page footer2', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: [
   
      {
        name: 'group',
        children: ' Designed by losenviskas',
        className: 'copyright-group',
      },
      {
        name: 'image2',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/fgGmQUfiUfSBfvsQpfOj.svg',
        className: 'copyright-line',
      },
      {
        name: 'copyright',
        children: 'Copyright © 2020',
        className: 'copyright-text',
      },
    ],
  },

};
let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
let smobi;
if(isMobile){
   smobi={
    padding:"0px"
  }
}


const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: text => <a>{text}</a>,
  },
  {
    title: 'Website',
    dataIndex: 'Website',
    key: 'Website',
  },
  {
    title: 'Apps',
    dataIndex: 'Mobile_apps',
    key: 'tags',
      
  },
  {
    title: 'Software',
    key: 'tags',
    dataIndex: 'tags',
    // render: tags => (
    //   <>
    //     {tags.map(tag => {
    //       let color = tag.length > 5 ? 'geekblue' : 'green';
    //       if (tag === 'loser') {
    //         color = 'volcano';
    //       }
    //       return (
    //         <Tag color={color} key={tag}>
    //           {tag.toUpperCase()}
    //         </Tag>
    //       );
    //     })}
    //   </>
    // ),
  },

];

const data = [
  {
    key: '1',
    name:"Domain registration",
    Website:  "ksh 2,000",
    Mobile_apps:  "ksh 2,000",
    tags: [""],
  },
  {
    key: '2',
    name:"Server hosting",
    Website:  "ksh 5,000",
    Mobile_apps:  "ksh 5,000",
    tags: ["ksh 5,000"],
  },
  {
    key: '3',
    name:"SSl",
    Website:  "free",
    Mobile_apps:  "free",
    tags: ["free"],
  },
  {
    key: '4',
    name:"SEO",
    Website:  "free",
    Mobile_apps:  "",
    tags: [""],
  },{
    key: '5',
    name:"Responisve designs",
    Website:  "free",
    Mobile_apps:  "",
    tags: [""],
  },{
    key: '6',
    name:"Cross platform development",
    Website:  "",
    Mobile_apps:  "ksh 5,000 for every platform",
    tags: ["ksh 5,000 for every platform"],
  },
  {
    key: '7',
    name:"React for fast loading speed",
    Website:  "free",
    Mobile_apps:  "",
    tags: [""],
  },
];

function App() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    
      if(viewerIsOpen){
        // alert(viewerIsOpen);
        setCurrentImage(0);
        setViewerIsOpen(false);
      }
  };
  const al = () => {
    
   alert("foo");
};
  return (
   
    <div  style={{paddingTop:"73px",backgroundColor:"black"}}>
        

        <MDBCard style={{ width:"100%",maxWidth: "1400px",marginRight: "auto",marginLeft: "auto",paddingBottom:"2px",marginTop:"3px",backgroundSize:"cover",background:"linear-gradient(146deg,  black 8%, black 25%, white 9%, white 82%, black 7%, black 98%, rgb(44, 62, 80) 1%) center center / cover fixed"}} className="text-center">
    <MDBCardBody  style={smobi}>
 <MDBCard style={{ width:"100%",maxWidth: "1400px",marginRight: "auto",marginLeft: "auto",marginBottom:"72px",paddingBottom:"52px",marginTop:"3px",backgroundSize:"cover",background:"linear-gradient(147deg, rgba(8, 0, 0, 0.01) 10%, rgb(255, 255, 255) 4%, rgb(255, 255, 255) 97%, rgba(0, 0, 0, 0.06) 4%)",backgroundAttachment: "fixed",backgroundPosition: "center"}} className="text-center">
    <MDBCardBody  style={smobi}>

    <h5 style={{}}>Prices</h5>
      <MDBContainer>
        <MDBRow>
          <MDBCol md="3">
            <MDBCard color="white lighten-1" style={{boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",maxWidth:"800px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">

            <FontAwesome className="olive-text pr-3" name="laptop" size="5x"spinstyle={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)' }}></FontAwesome>

            <MDBCardBody style={{color:"#697b8c"}}>
              <h5 style={{}}>Website development</h5>
              <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >From ksh 5,000 <br/><br/>The price will vary depending on the website you require.</p> 

                <br/>
                <Link  to="/contact us">
                <u>contact us for more info</u> 
              </Link> 
            
            </MDBCardBody>
          </MDBCard>
          </MDBCol>
          <MDBCol md="3">
            <MDBCard color="white lighten-1" style={{boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">  
            <FontAwesome className="olive-text pr-3" name="mobile" size="5x"spinstyle={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)' }}></FontAwesome>
                <MDBCardBody style={{color:"#697b8c"}}>
                  <h5 style={{}}>Mobile applications</h5>
                  <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >Price From 20000 ksh<br/><br/>We charge mobile apps based on the content and complexity </p>
                  
                   <br/>
                <Link  to="/contact us">
                <u>contact us for more info</u> 
              </Link> 
                
                </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md="3">
            <MDBCard color="white lighten-1" style={{boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">
            <FontAwesome className="olive-text pr-3" name="code" size="5x"spinstyle={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)' }}></FontAwesome>
                <MDBCardBody style={{color:"#697b8c"}}>
                  <h5 style={{}}>Software development</h5>
                  <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >Price From ksh. 25000 <br/><br/>We charge software development based on type and complexity </p>
                
                  <br/>
                <Link  to="/contact us">
                <u>contact us for more info</u> 
              </Link>
                </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md="3">
            <MDBCard color="white lighten-1" style={{boxShadow:"0 32px 32px rgba(34, 94, 222, 0.08)",maxWidth:"700px",marginLeft:"auto",marginRight:"auto",marginTop:"32px"}} text="black" className="text-center">
                <FontAwesome className="olive-text pr-3" name="microchip" size="5x"spinstyle={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)' }}></FontAwesome>
                <MDBCardBody style={{color:"#697b8c"}}>
                  <h5 style={{}}>Microcontroller programing</h5>
                  <p style={{whiteSpace:"pre-wrap",marginTop:"22px"}} >Price from ksh. 35000 <br/><br/>
                    We charge Microcontroller programing based on the type of microcontroller and the design. </p>
                
                  <br/>
                <Link  to="/contact us">
                <u>contact us for more info</u> 
              </Link>
                </MDBCardBody>
            </MDBCard>
          </MDBCol>
          
        </MDBRow>
        <br/><br/><br/>
        <Table columns={columns} dataSource={data} style={{margnTop:"333px",}}/>
      </MDBContainer>
    </MDBCardBody>
  </MDBCard>
    </MDBCardBody>
  </MDBCard> 
  <Footer0
        id="Footer0_0"
        key="Footer0_0"
        dataSource={Footer20DataSource}
        // isMobile={this.state.isMobile}
      />
    </div>
  );
}
// render(<App />, document.getElementById("app"));

export default  App;