import React from "react";
import { Route, Switch } from "react-router-dom";

import home from "./larsen/Home/index";
import grid from "./larsen/grid";
import past from "./larsen/past";
import price from "./larsen/Prices";
import Gallery from "./larsen/m";
import Quote from "./larsen/quote";
import more from "./larsen/more1";
import contact from "./larsen/contact";

import Services from "./larsen/Services";

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        
        <Route exact path ="/"component={home}/>
        <Route exact path ="/grid"component={grid}/>
        <Route exact path ="/Past events"component={past}/>
        <Route exact path ="/prices"component={price}/>
        <Route exact path ="/gallery"component={Gallery}/>
        <Route exact path ="/quote"component={Quote}/>
        
        <Route exact path ="/more"component={more}/>
        <Route exact path ="/services"component={Services}/>
        <Route exact path ="/contact us"component={contact}/>
        <Route
          render={function () {
            return <Route exact path ="/"component={home}/>;
          }}
        />
      </Switch>
    );
  }
}

export default Routes;
